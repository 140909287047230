export default AppHeader;

function AppHeader( {appName} ) {

  return (
    
    <header className="App-header">

      {/* <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a> */}

      <h1>民的国1911 — ROC 1911</h1>
      <a className="App-header-link" href="https://open.substack.com/pub/walker1911/p/a63">
        <p>“民的国”永存不灭</p>
      </a>

    </header>

  );
}