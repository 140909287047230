export default AppMain;

function AppMain() {

  return (
    
    <main className="App-main">

      <p>感谢各位的关注和支持</p>
      
      <a className="App-link" href="https://accounts.roc1911.org">
        <h2>“民的国1911”用户帐号系统 — Alpha测试</h2>
      </a>

      <p>现在已经开放</p>

    </main>
    
  );
  
}