export default AppFooter;

function AppFooter( {appName} ) {

  return (

    <footer className="App-footer">

      <p>
        <a className="App-footer-link" href="https://walker1911.me">行者1911</a>&nbsp; @ &nbsp;
        <a className="App-footer-link" href="https://capital1911.org">叛都1911</a>&nbsp; in &nbsp;
        <a className="App-footer-link" href="https://roc1911.org">民的国1911</a>
      </p>

      <p>
        © 2024 民的国1911 — ROC 1911. All rights reserved.
      </p>
      
    </footer>
    
  );

}