// import logo from './logo.svg';

import './App.css';

import AppHeader from './components/AppHeader';
import AppMain from './components/AppMain';
import AppFooter from './components/AppFooter';

function App() {

  const appName = "民的国1911 — ROC 1911";
  
  return (
    <div className="App">

      <AppHeader appName={appName} />

      <AppMain />

      <AppFooter appName={appName} />

    </div>
  );
}

export default App;
